<script lang="ts" setup>import { shallowRef as _shallowRef, computed as _computed, ref as _ref } from 'vue';

import type { ApiMsg } from '@/composables/fetch'

const msgEl = _shallowRef<HTMLDivElement>()
const msgEls = _computed<HTMLDivElement[]>(() => msgEl.value
  ? [].slice.call(msgEl.value.childNodes, 1, msgEl.value.childNodes.length - 1)
      .filter((el: Node) => el.nodeType === 1)
  : [])
const beforeMsgsEl = _shallowRef<HTMLDivElement>()
const afterMsgsEl = _shallowRef<HTMLDivElement>()

const msgs = useMsgs()

let domUpdateTrigger = _ref(false)
watch(() => msgs.msgs, async () => {
  if (!msgEl.value)
    return

  if (domUpdateTrigger.value)
    return
  domUpdateTrigger.value = true

  triggerRef((msgEl))
  const authorEl = [...msgEls.value].reverse().find(el => el.offsetTop < msgEl.value.scrollTop + msgEl.value.clientHeight)
    ?? msgEls.value[0]
  const authorRect = authorEl?.getBoundingClientRect()

  await nextTick()

  if (authorEl?.parentElement && authorRect) {
    const authorRectNew = authorEl.getBoundingClientRect()
    const offset = authorRectNew.bottom - authorRect.bottom
    msgEl.value.scrollTop += offset
  }
  else {
    msgEl.value.scrollTop = 1 << 30
  }

  domUpdateTrigger.value = false
})

useIntersectionObserver((beforeMsgsEl), ([{ isIntersecting }]) => {
  if (isIntersecting)
    msgs.fetchBefore()
}, { rootMargin: `${window.innerHeight * 0.4}px 0px`, root: (msgEl) })
useIntersectionObserver((afterMsgsEl), ([{ isIntersecting }]) => {
  if (isIntersecting)
    msgs.loadAfter()
}, { rootMargin: `${window.innerHeight * 0.4}px 0px`, root: (msgEl) })

onMounted(() => {
  msgEl.value!.scrollTop = 1 << 30
})

const { arrivedState } = useScroll((msgEl))

function onJumpTo(msg: ApiMsg) {
  msgs.jumpTo(msg)
}
msgs.onJumpTo.on(async (msg: ApiMsg) => {
  let authorEl
  let retry = 0
  for (;;) {
    if (!msgEl.value)
      return

    triggerRef((msgEl))
    authorEl = msgEls.value.find(el => el.dataset.msgid === msg.id)

    if (!domUpdateTrigger.value && authorEl)
      break

    if (retry++ > 10)
      return

    await nextTick()
  }

  msgEl.value.scrollTop = authorEl.offsetTop + authorEl.clientHeight - msgEl.value.clientHeight * 2 / 3
})

let cmId = _ref<string | undefined>()
let cmPos = _ref<{ x: number, y: number }>({ x: 0, y: 0 })
useEventListener('contextmenu', (e) => {
  if (cmId.value) {
    cmId.value = undefined
    return
  }

  e.preventDefault()

  if (e.target instanceof Element) {
    const id = e.target.closest('[data-msgid]')?.getAttribute('data-msgid')
    if (id) {
      cmId.value = id
      cmPos.value = { x: e.clientX, y: e.clientY }
      return
    }
  }

  cmId.value = undefined
})
useEventListener('click', () => {
  cmId.value = undefined
})
function copyLink(id: string) {
  const url = `${window.location.origin}/?msgid=${id}`
  navigator.clipboard.writeText(url)
}
</script>

<template>
  <div class="re fa fc min-h-0">
    <div class="z-50 ab r-2 t-12 rd-full sd-md bg-white">
      <div v-if="msgs.fetching" class="i-carbon:smoothing m-1 w-6 h-6 tx-gray-500 animate-spin" />
      <div
        v-else-if="arrivedState.bottom"
        class="i-carbon:rotate-360 m-1 w-6 h-6 tx-gray-500 cursor-pointer"
        @click="async () => {
          await msgs.fetchAfter()
          await nextTick()
          if (msgEl)
            msgEl.scrollTop = msgEl.scrollHeight
        }"
      />
      <div
        v-else
        class="i-carbon:chevron-down m-1 w-6 h-6 tx-gray-500 cursor-pointer"
        @click="async () => {
          msgs.toBottom()
          await nextTick()
          if (msgEl)
            msgEl.scrollTop = msgEl.scrollHeight
        }"
      />
    </div>

    <div ref="msgEl" class="fc min-h-full pb-2 bg-sky-200 of-y-scroll">
      <div v-show="!msgs.msgs[0]?.top" ref="beforeMsgsEl" class="fn h-80vh" />
      <MsgMessage
        v-for="(msg, index) in msgs.msgs" :key="msg.id"
        :msg="msg"
        :data-msgid="msg.id"
        :first="msgs.msgs[index - 1]?.attributes.fromUserId !== msg.attributes.fromUserId"
        :last="msgs.msgs[index + 1]?.attributes.fromUserId !== msg.attributes.fromUserId"
        :show-jump="!!msgs.searchFilter"
        :highlight="msgs.highlightMsgId === msg.id"
        @jump-to="onJumpTo(msg)"
      />
      <div v-show="!msgs.msgs.at(-1)?.end" ref="afterMsgsEl" class="fn h-80vh" />
    </div>

    <Teleport to="body">
      <div
        v-if="cmId"
        class="fixed rounded-xl border-1 border-gray-200 bg-white"
        :style="{ left: `${cmPos.x}px`, top: `${cmPos.y}px` }"
      >
        <div
          class="flex items-center gap-1 px-2 py-1 hover:text-sky-600 cursor-pointer"
          @click="copyLink(cmId)"
        >
          <div class="i-carbon:link w-5 h-5" />
          复制链接
        </div>
      </div>
    </Teleport>
  </div>
</template>
