<script lang="ts" setup>import { shallowRef as _shallowRef } from 'vue';

import type { Zoom } from 'medium-zoom'

defineProps({
  html: null
})

const mediumZoom = inject<Zoom>('mediumZoom')

const el = _shallowRef<HTMLDivElement>()

onMounted(() => {
  mediumZoom?.attach(el.value!.querySelectorAll('img[data-zoomable]'))
})
onBeforeUnmount(() => {
  mediumZoom?.detach(el.value!.querySelectorAll('img[data-zoomable]'))
})
</script>

<template>
  <p ref="el" v-html="html" />
</template>
