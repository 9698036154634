export const useOptions = defineStore('options', () => {
  // options
  const fetchToken = useLocalStorage('fetchToken', '', { writeDefaults: false })
  const fetchPageSize = useLocalStorage('fetchPageSize', 40, { writeDefaults: false })
  const imageServer = useLocalStorage('imageServer', 'cloudflare', { writeDefaults: false })
  const joinedImageUrl = useLocalStorage('joinedImageUrl', true, { writeDefaults: false })

  const screenPages = useLocalStorage('screenPages', 5, { writeDefaults: false })
  const showUnsupportedCQCodes = useLocalStorage('showUnsupportedCQCodes', true, { writeDefaults: false })
  const showImagePlaceholder = useLocalStorage('showImagePlaceholder', true, { writeDefaults: false })
  const fastImagePreload = useLocalStorage('fastImagePreload', false, { writeDefaults: false })
  const fastImagePreloadJpeg = useLocalStorage('fastImagePreloadJpeg', false, { writeDefaults: false })

  // helpers
  const screenPageSize = computed(() => fetchPageSize.value * screenPages.value)

  const getImageUrl = (file: string, url: string, width?: number, height?: number) => {
    const md5 = file.replace(/\.image$/, '').toUpperCase()

    if (joinedImageUrl.value)
      url = `https://gchat.qpic.cn/gchatpic_new/0/0-0-${md5}/0?term=2&is_origin=0`

    switch (imageServer.value) {
      case 'local':
        return url.replace(/^https:\/\/([^/]+)\.qpic\.cn\/(.+)/, '/proxy/qpic/$1/$2')
      case 'imagex':
        if (width && height)
          return `https://qq-proxy.imagex-test.forestsay.cc/${md5}/0~tplv-9um7k6yb0n-thumb:${width}:${height}.image`
        else
          return `https://qq-proxy.imagex-test.forestsay.cc/${md5}/0`
      case 'wsrv.nl':
        if (width && height)
          return `//wsrv.nl/?url=${encodeURIComponent(url)}&w=${width}&h=${height}`
        else
          return `//wsrv.nl/?url=${encodeURIComponent(url)}`
      case 'cloudflare':
      default:
        return url.replace(/^https:\/\/([^/]+)\.qpic\.cn\/(.+)/, '/proxy/qpic/$1/$2')
    }
  }

  const reset = () => {
    fetchToken.value = ''
    fetchPageSize.value = 40
    imageServer.value = 'cloudflare'
    joinedImageUrl.value = true
    screenPages.value = 5
    showUnsupportedCQCodes.value = true
    showImagePlaceholder.value = true
    fastImagePreload.value = false
    fastImagePreloadJpeg.value = false
  }

  return {
    fetchToken,
    fetchPageSize,
    imageServer,
    joinedImageUrl,
    fastImagePreload,
    fastImagePreloadJpeg,
    screenPages,
    showUnsupportedCQCodes,
    showImagePlaceholder,

    screenPageSize,

    getImageUrl,

    reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useOptions, import.meta.hot))
