import { escapeUTF8 } from 'entities'
import linkifyHtml from 'linkify-html'
import cqFaces from 'virtual:cq-faces.js'

// @unocss-include

interface CqContext {
  options: ReturnType<typeof useOptions>
  imageCache: ReturnType<typeof useImageCache>
  msgs: ReturnType<typeof useMsgs>
}

// helpers
function cqDecode(str: string) {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&#91;/g, '[')
    .replace(/&#93;/g, ']')
    .replace(/&#44;/g, ',')
}
function linkify(str: string) {
  return linkifyHtml(str, {
    className: 'tx-sky-500',
    rel: 'noopener noreferrer',
    target: {
      url: '_blank',
    },
  })
}
const cqText = (str = '') => linkify(escapeUTF8(cqDecode(str)))
function cqParseAttr(str: string) {
  return Object.fromEntries(
    str
      .split(',')
      .filter(Boolean)
      .map((p) => {
        const [, k, v] = p.match(/^(\w+)=([\s\S]*)$/)!
        return [k, cqDecode(v)]
      }),
  ) as Record<string, string>
}

// blocks
async function cqImage({ options, imageCache }: CqContext, file: string, url: string) {
  let src = options.getImageUrl(file, url, 256, 256)

  // preload dimensions
  let dimensions = imageCache.dims.get(src) ?? ''
  if (!dimensions) {
    try {
      let w = 0
      let h = 0
      if (options.fastImagePreload) {
        const dim = await fetch(src)
          .then(async (res) => {
            const contentType = res.headers.get('content-type')!

            if (contentType.startsWith('image/jpeg') && options.fastImagePreloadJpeg) {
              // const SOF_B = [0xFF, 0xC0] // Start Of Frame (Baseline)
              // const SOF_P = [0xFF, 0xC2] // Start Of Frame (Progressive)
              const reader = res.body!.getReader()
              const data = new Uint8Array(10)
              let offset = 0
              for (; ;) {
                const { done, value } = await reader.read()
                if (done)
                  throw new Error('EOF')

                if (offset >= 2) {
                  // it's SOF_B or SOF_P
                  data.set(value.slice(0, 9 - offset), offset)
                  offset += value.length
                }
                if (offset === 1) {
                  // found the first byte, but not sure if it's SOF_B or SOF_P
                  if (value[0] === 0xC0 || value[0] === 0xC2) {
                    // it's SOF_B or SOF_P
                    data.set(value.slice(0, 9), 1)
                    offset += value.length
                  }
                  else {
                    offset = 0
                  }
                }
                if (offset === 0) {
                  let idx = value.indexOf(0xFF)
                  while (idx !== -1) {
                    // found the first byte, but not sure if it's SOF_B or SOF_P
                    if (value[idx + 1] === 0xC0 || value[idx + 1] === 0xC2) {
                      // it's SOF_B or SOF_P
                      data.set(value.slice(idx, idx + 9), 0)
                      offset = value.length - idx
                      break
                    }
                    idx = value.indexOf(0xFF, idx + 1)
                  }
                }
                // just make sure we've collected enough bytes
                if (offset < 10)
                  continue

                // read the dimensions
                const view = new DataView(data.buffer)
                const h = view.getUint16(5)
                const w = view.getUint16(7)
                return { w, h }
              }
            }

            else if (contentType.startsWith('image/gif')) {
              // https://www.fileformat.info/format/gif/egff.htm
              const reader = res.body!.getReader()
              const data = new Uint8Array(10) // 3 + 3 + 2 + 2
              let offset = 0
              for (; ;) {
                const { done, value } = await reader.read()
                if (done)
                  throw new Error('EOF')

                data.set(value.slice(0, 10 - offset), offset)
                offset += value.length

                if (offset >= 10)
                  break
              }

              const view = new DataView(data.buffer)
              const w = view.getUint16(6, true)
              const h = view.getUint16(8, true)
              return { w, h }
            }

            else if (contentType.startsWith('image/png')) {
              const reader = res.body!.getReader()
              const data = new Uint8Array(24) // 8 + 4 + 4 + 4 + 4
              let offset = 0
              for (;;) {
                const { done, value } = await reader.read()
                if (done)
                  throw new Error('EOF')

                data.set(value.slice(0, 24 - offset), offset)
                offset += value.length

                if (offset >= 24)
                  break
              }

              const view = new DataView(data.buffer)
              const w = view.getUint32(16)
              const h = view.getUint32(20)
              return { w, h }
            }

            else {
              const blob = await res.blob()
              const image = new Image()
              image.src = URL.createObjectURL(blob)
              await new Promise((resolve, reject) => {
                image.onload = resolve
                image.onerror = reject
              })
              URL.revokeObjectURL(image.src)
              return { w: image.naturalWidth, h: image.naturalHeight }
            }
          })
        w = dim.w
        h = dim.h
      }
      else {
        const image = new Image()
        image.src = src
        await new Promise((resolve, reject) => {
          image.onload = resolve
          image.onerror = reject
        })
        w = image.naturalWidth
        h = image.naturalHeight
      }

      dimensions = ` width="${w}" height="${h}"`
      imageCache.dims.set(src, dimensions)
    }
    catch (e) {
      console.error(e)
      if (options.showImagePlaceholder) {
        src = `data:image/svg+xml,%3Csvg width='64' height='64' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='60' height='60' style='fill:%23dedede;stroke:%23555555;stroke-width:2'/%3E%3Ctext x='50%25' y='50%25' font-size='18' text-anchor='middle' alignment-baseline='middle' font-family='monospace, sans-serif' fill='%23555555'%3E%3C/text%3E%3C/svg%3E`
        dimensions = ` width="64" height="64"`
      }
    }
  }

  return `<img data-zoomable class="max-w-64 max-h-64 object-contain" src="${src}"${dimensions} data-zoom-src="${options.getImageUrl(file, url)}">`
}
function cqMension({ msgs }: CqContext, qq: string) {
  if (msgs.users.has(qq)) {
    const user = msgs.users.get(qq)!
    return `<span class="text-sky-500"><img class="inline-block pr-px w-2ch rounded-full align-middle object-cover" src="https://q2.qlogo.cn/g?b=qq&nk=${qq}&s=40" />@${cqText(user.displayName || user.nick)}</span>`
  }
  else {
    return `<span class="text-sky-300"><img class="inline-block pr-px w-2ch rounded-full align-middle object-cover" src="https://q2.qlogo.cn/g?b=qq&nk=${qq}&s=40" />@${cqText(qq)}</span>`
  }
}
function cqReply(ctx: CqContext, id: string, qq?: string) {
  let res = '<div class="inline-flex gap-1"><div class="st w-.5 bg-sky-300"></div><div class="tx-sm tx-gray-500">'
  res += `回复 ${cqText(id)}`
  if (qq)
    res += ` ${cqMension(ctx, qq)}`
  res += '</div></div><br />'
  return res
}
async function cqFace(id: string) {
  const url = cqFaces[id]
  if (url)
    return `<img class="inline w-1.5em h-1.5em" src="${url}">`
  return ''
}
function cqJson(ctx: CqContext, data: string, type: string, attr: string) {
  try {
    const prefix = () => ctx.options.showUnsupportedCQCodes
      ? `<span class="pl-1 tx-sm bd-l-2 bd-gray-300">${cqUnsupported(ctx, type, attr)}<br /></span>`
      : ''
    const parsed = JSON.parse(data)
    switch (parsed.app) {
      case 'com.tencent.miniapp_01': {
        if (parsed.meta.detail_1.appid === '1109937557') {
          return [
            prefix(),
            '<span class="inline-block align-middle i-tabler:brand-bilibili"></span>',
            escapeUTF8(parsed.meta.detail_1.desc),
            '<br />',
            `<a class="tx-sky-500" rel="noopener noreferrer" target="_blank" href="${parsed.meta.detail_1.qqdocurl}">`,
            parsed.meta.detail_1.qqdocurl.length > 36
              ? `${escapeUTF8(`${parsed.meta.detail_1.qqdocurl.slice(0, 36)}...`)}`
              : escapeUTF8(parsed.meta.detail_1.qqdocurl),
            '</a><br />',
          ].join('')
        }

        else {
          return cqUnsupported(ctx, type, attr)
        }
      }

      case 'com.tencent.multimsg': {
        return [
          prefix(),
          `<span class="pl-1 tx-gray-700 bd-l-2 bd-gray-300">${escapeUTF8(parsed.meta.detail.source)}</span>`,
          '<br />',
          ...parsed.meta.detail.news.map((n: any) => `${escapeUTF8(n.text)}<br />`),
          `<span class="tx-gray-700">${escapeUTF8(parsed.meta.detail.summary)}</span>`,
        ].join('')
      }

      case 'com.tencent.structmsg': {
        if (parsed.view === 'news') {
          return [
            prefix(),
            escapeUTF8(parsed.prompt),
            '<br />',
            `<a class="tx-sky-500" rel="noopener noreferrer" target="_blank" href="${parsed.meta.news.jumpUrl}">`,
            parsed.meta.news.jumpUrl.length > 36
              ? `${escapeUTF8(`${parsed.meta.news.jumpUrl.slice(0, 36)}...`)}`
              : escapeUTF8(parsed.meta.news.jumpUrl),
            '</a>',
          ].join('')
        }

        else {
          return cqUnsupported(ctx, type, attr)
        }
      }

      default: {
        return cqUnsupported(ctx, type, attr)
      }
    }
  }
  catch (e) {
    return cqUnsupported(ctx, type, attr)
  }
}
function cqUnsupported(ctx: CqContext, type: string, attr: string) {
  if (ctx.options.showUnsupportedCQCodes) {
    const long = attr.length > 21
    const shortCode = escapeUTF8('[CQ:' + `${type}${long ? `${attr.slice(0, 21)}...` : attr}]`)
    const code = escapeUTF8('[CQ:' + `${type}${attr}]`)
    return `<span class="tx-gray-400" title="${code}">${shortCode}</span>`
  }
  return ''
}

export async function cqRender(content: string) {
  const ctx = {
    options: useOptions(),
    imageCache: useImageCache(),
    msgs: useMsgs(),
  }

  let left = content
  let res = ''
  while (left.length > 0) {
    const match = left.match(/\[CQ:(\w+)(,[^\]]+)?\]/)
    if (!match) {
      res += cqText(left)
      break
    }

    // push the text before the code
    res += cqText(left.slice(0, match.index!))
    left = left.slice(match.index! + match[0].length)

    const [, type, attr] = match
    const attrs = cqParseAttr(attr)
    switch (type) {
      // [CQ:image,file=000.image,subType=0,url=https://gchat.qpic.cn/..]
      case 'image': {
        res += await cqImage(ctx, attrs.file, attrs.url)
        break
      }
      // [CQ:reply,id=123456789][CQ:at,qq=123456789]
      case 'reply': {
        const matchAt = left.match(/^\[CQ:at,qq=(\d+)\]/)
        if (matchAt)
          left = left.slice(matchAt[0].length)
        left = left.trimStart()

        res += cqReply(ctx, attrs.id, matchAt?.[1])

        break
      }
      // [CQ:at,qq=123456789]
      case 'at': {
        res += cqMension(ctx, attrs.qq)
        break
      }
      // [CQ:face,id=1]
      case 'face': {
        res += await cqFace(attrs.id)
        break
      }
      // [CQ:json,data={...}]
      case 'json': {
        res += cqJson(ctx, attrs.data, type, attr)
        break
      }
      default: {
        res += cqUnsupported(ctx, type, attr)
        break
      }
    }
  }

  return res
}
